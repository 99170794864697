.urlForm {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0.5em 1em;
    border: solid black 1px;
    width: 25em;
}

.urlForm__input {
    font-size: 1.5em;
    padding: 0 0.2em;
}

.urlForm__buttons {
    display: flex;
    justify-content: center;
}

.urlForm__button {
    font-size: 1em;
    margin: 0.5em 0.25em 0 0.25em;
}

.urlForm__button--submit {
    font-weight: bold;
}

@media (max-width: 750px) {
    .urlForm {
        width: calc(100vw - 5em);
    }
}